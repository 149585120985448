/**
 * Consent Mode functions
 */

(function (wpm, $, undefined) {

	/**
	 * Handle Cookie Management Platforms
	 */

	const getComplianzCookies = () => {

		let cmplz_statistics     = wpm.getCookie("cmplz_statistics")
		let cmplz_marketing      = wpm.getCookie("cmplz_marketing")
		let cmplz_consent_status = wpm.getCookie("cmplz_consent_status") || wpm.getCookie("cmplz_banner-status")

		if (cmplz_consent_status) {
			return {
				analytics       : cmplz_statistics === "allow",
				ads             : cmplz_marketing === "allow",
				visitorHasChosen: true,
			}
		}

		return false
	}

	const getCookieLawInfoCookies = () => {

		let analyticsCookie  = wpm.getCookie("cookielawinfo-checkbox-analytics") || wpm.getCookie("cookielawinfo-checkbox-analytiques")
		let adsCookie        = wpm.getCookie("cookielawinfo-checkbox-advertisement") || wpm.getCookie("cookielawinfo-checkbox-performance") || wpm.getCookie("cookielawinfo-checkbox-publicite")
		let visitorHasChosen = wpm.getCookie("CookieLawInfoConsent")

		if (analyticsCookie || adsCookie) {

			return {
				analytics       : analyticsCookie === "yes",
				ads             : adsCookie === "yes",
				visitorHasChosen: !!visitorHasChosen,
			}
		}

		return false
	}

	const getTermlyCookies = () => {

		// Get Termly cookies from localStorage from termly_gtm_template_default_consents for ad_storage and for analytics_storage
		let termlyConsent = localStorage.getItem("termly_gtm_template_default_consents")

		if (termlyConsent) {
			termlyConsent = JSON.parse(termlyConsent)

			return {
				analytics       : termlyConsent.analytics_storage === "granted",
				ads             : termlyConsent.ad_storage === "granted",
				visitorHasChosen: true,
			}
		}

		return false
	}


	/**
	 * Initialize and set default values
	 */

	let
		wpmConsentValues              = {}
	wpmConsentValues.categories       = {}
	wpmConsentValues.pixels           = []
	wpmConsentValues.mode             = "category"
	wpmConsentValues.visitorHasChosen = false

	// Return current consent values
	wpm.getConsentValues = () => wpmConsentValues

	wpm.setConsentValueCategories = (analytics = false, ads = false) => {
		wpmConsentValues.categories.analytics = analytics
		wpmConsentValues.categories.ads       = ads
	}

	// Update the PMW consent values with values coming from a CMP
	wpm.updateConsentCookieValues = (analytics = null, ads = null, explicitConsent = false) => {

		// ad_storage
		// analytics_storage
		// functionality_storage
		// personalization_storage
		// security_storage

		let cookie

		/**
		 * Setup defaults
		 */

		// consentValues.categories.analytics = true
		// consentValues.categories.ads       = true

		wpmConsentValues.categories.analytics = !explicitConsent
		wpmConsentValues.categories.ads       = !explicitConsent


		if (analytics || ads) {

			if (analytics) {
				wpmConsentValues.categories.analytics = !!analytics
			}

			if (ads) {
				wpmConsentValues.categories.ads = !!ads
			}

			return
		}

		/**
		 * PMW Cookie Consent
		 *
		 * Must be before every other CMP for the case that one of the included CMPs
		 * decides to implement the PMW cookie consent API. In that case
		 * the PMW consent cookie must take precedence.
		 */

		if (cookie = wpm.getCookie("pmw_cookie_consent")) {

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Cookiebot
		 * https://wordpress.org/plugins/cookiebot/
		 */

		if (cookie = wpm.getCookie("CookieConsent")) {

			cookie = decodeURI(cookie)

			wpmConsentValues.categories.analytics = cookie.indexOf("statistics:true") >= 0
			wpmConsentValues.categories.ads       = cookie.indexOf("marketing:true") >= 0
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Cookie Script
		 * https://wordpress.org/plugins/cookie-script-com/
		 */

		if (cookie = wpm.getCookie("CookieScriptConsent")) {

			cookie = JSON.parse(cookie)

			if (cookie.action === "reject") {
				wpmConsentValues.categories.analytics = false
				wpmConsentValues.categories.ads       = false
			} else if (cookie.categories.length === 2) {
				wpmConsentValues.categories.analytics = true
				wpmConsentValues.categories.ads       = true
			} else {
				wpmConsentValues.categories.analytics = cookie.categories.indexOf("performance") >= 0
				wpmConsentValues.categories.ads       = cookie.categories.indexOf("targeting") >= 0
			}

			wpmConsentValues.visitorHasChosen = true

			return
		}

		/**
		 * Borlabs Cookie
		 * https://borlabs.io/borlabs-cookie/
		 */
		if (cookie = wpm.getCookie("borlabs-cookie")) {

			cookie = decodeURI(cookie)
			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = !!cookie?.consents?.statistics
			wpmConsentValues.categories.ads       = !!cookie?.consents?.marketing
			wpmConsentValues.visitorHasChosen     = true
			wpmConsentValues.pixels               = [...cookie?.consents?.statistics || [], ...cookie?.consents?.marketing || []]
			wpmConsentValues.mode                 = "pixel"

			return
		}

		/**
		 * Complianz Cookie
		 * https://wordpress.org/plugins/complianz-gdpr/
		 */

		if (cookie = getComplianzCookies()) {

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen

			return
		}

		/**
		 * Cookie Compliance (free version)
		 * https://wordpress.org/plugins/cookie-notice/
		 */

		if (cookie = wpm.getCookie("cookie_notice_accepted")) {

			wpmConsentValues.categories.analytics = true
			wpmConsentValues.categories.ads       = true
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Cookie Compliance (pro version)
		 * https://wordpress.org/plugins/cookie-notice/
		 */

		if (cookie = wpm.getCookie("hu-consent")) {

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = !!cookie.categories["3"]
			wpmConsentValues.categories.ads       = !!cookie.categories["4"]
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * CookieYes, GDPR Cookie Consent (Cookie Law Info)
		 * https://wordpress.org/plugins/cookie-law-info/
		 */

		if (cookie = getCookieLawInfoCookies()) {

			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen === true

			return
		}

		/**
		 * GDPR Cookie Compliance Plugin by Moove Agency
		 * https://wordpress.org/plugins/gdpr-cookie-compliance/
		 *
		 * TODO write documentation on how to set up the plugin in order for this to work properly
		 */

		if (cookie = wpm.getCookie("moove_gdpr_popup")) {

			cookie = JSON.parse(cookie)

			wpmConsentValues.categories.analytics = cookie.thirdparty === "1"
			wpmConsentValues.categories.ads       = cookie.advanced === "1"
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * WP AutoTerms
		 * https://wordpress.org/plugins/auto-terms-of-service-and-privacy-policy/
		 */

		if (cookie = wpm.getCookie("wpautoterms-cookies-notice")) {

			if (cookie !== "1") return

			wpmConsentValues.categories.analytics = true
			wpmConsentValues.categories.ads       = true
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Usercentrics
		 *
		 * https://usercentrics.com/
		 * https://docs.usercentrics.com/#/cmp-v2-ui-api
		 * https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesbaseinfo
		 */

		if (window.localStorage && window.localStorage.getItem("uc_settings")) {

			console.log("Usercentrics settings detected")

			if (typeof UC_UI === "undefined") {

				// register event to block unblock after UC_UI library is loaded
				window.addEventListener("UC_UI_INITIALIZED", (event) => {
					wpm.ucUiProcessConsent()
				})

				// Don't continue because in here the UC_UI library is not loaded yet
				return
			}

			if (UC_UI.areAllConsentsAccepted()) {
				wpmConsentValues.categories.analytics = true
				wpmConsentValues.categories.ads       = true
				wpmConsentValues.visitorHasChosen     = true

				return
			}

			wpm.ucUiProcessConsent()
		}

		/**
		 * OneTrust
		 *
		 * https://www.onetrust.com/
		 */

		if (cookie = wpm.getCookie("OptanonConsent")) {

			// console.log("OneTrust settings detected")

			let params = new URLSearchParams(cookie)
			let groups = params.get("groups").split(",")

			// Groups is an array like this ['1:1', '2:0', '3:1', '4:1']. Make it an object with key value pairs
			let groupsObject = {}
			groups.forEach((group) => {

				let groupArray              = group.split(":")
				groupsObject[groupArray[0]] = groupArray[1]
			})

			// group mapping
			// 1 = necessary
			// 2 = analytics
			// 3 = functional
			// 4 = ads

			wpmConsentValues.categories.analytics = groupsObject["2"] === "1"
			wpmConsentValues.categories.ads       = groupsObject["4"] === "1"
			wpmConsentValues.visitorHasChosen     = true

			return
		}

		/**
		 * Termly
		 *
		 * https://www.termly.io/
		 */

		if (cookie = getTermlyCookies()) {
			wpmConsentValues.categories.analytics = cookie.analytics === true
			wpmConsentValues.categories.ads       = cookie.ads === true
			wpmConsentValues.visitorHasChosen     = cookie.visitorHasChosen === true
			return
		}
	}

	// Only run after having made sure that the UC_UI library is loaded
	wpm.ucUiProcessConsent = () => {

		if (typeof UC_UI === "undefined") return

		if (UC_UI.areAllConsentsAccepted()) {
			pmw.consentAcceptAll()
		}

		const ucStatisticsSlug = UC_UI.getSettingsLabels().categories.filter(data => data.label === "Statistics")[0].slug

		pmw.consentAdjustSelectively(
			{
				analytics: !UC_UI.getServicesBaseInfo().filter(data => data.categorySlug === ucStatisticsSlug && data.consent.status === false).length > 0,
				ads      : !UC_UI.getServicesBaseInfo().filter(data => data.categorySlug === "marketing" && data.consent.status === false).length > 0,
			},
		)
	}

	wpm.updateConsentCookieValues()

	wpm.setConsentDefaultValuesToExplicit = () => {
		wpmConsentValues.categories = {
			analytics: false,
			ads      : false,
		}
	}

	wpm.canIFire = (category, pixelName) => {

		let canIFireMode

		if ("category" === wpmConsentValues.mode) {
			canIFireMode = !!wpmConsentValues.categories[category]
		} else if ("pixel" === wpmConsentValues.mode) {
			canIFireMode = wpmConsentValues.pixels.includes(pixelName)

			// If a user sets "bing-ads" in Borlabs Cookie instead of
			// "microsoft-ads" in the Borlabs settings, we need to check
			// for that too.
			if (false === canIFireMode && "microsoft-ads" === pixelName) {
				canIFireMode = wpmConsentValues.pixels.includes("bing-ads")
			}
		} else {
			console.error("Couldn't find a valid consent mode in wpmConsentValues")
			canIFireMode = false
		}

		if (canIFireMode) {
			return true
		} else {
			if (true || wpm.urlHasParameter("debugConsentMode")) {
				wpm.logPreventedPixelLoading(pixelName, category)
			}

			return false
		}
	}

	wpm.logPreventedPixelLoading = (pixelName, category) => {

		if (wpmDataLayer?.shop?.cookie_consent_mgmt?.explicit_consent) {
			console.log("Pixel Manager for WooCommerce: The \"" + pixelName + " (category: " + category + ")\" pixel has not fired because you have not given consent for it yet. (PMW is in explicit consent mode.)")
		} else {
			console.log("Pixel Manager for WooCommerce: The \"" + pixelName + " (category: " + category + ")\" pixel has not fired because you have removed consent for this pixel. (PMW is in implicit consent mode.)")
		}
	}

	/**
	 * Runs through each script in <head> and blocks / unblocks it according to the plugin settings
	 * and user consent.
	 */

	// https://stackoverflow.com/q/65453565/4688612
	wpm.scriptTagObserver = new MutationObserver((mutations) => {
		mutations.forEach(({addedNodes}) => {
			[...addedNodes]
				.forEach(node => {

					if ($(node).data("wpm-cookie-category")) {

						// If the pixel category has been approved > unblock
						// If the pixel belongs to more than one category, then unblock if one of the categories has been approved
						// If no category has been approved, but the Google Consent Mode is active, then only unblock the Google scripts

						if (wpm.shouldScriptBeActive(node)) {
							wpm.unblockScript(node)
						} else {
							wpm.blockScript(node)
						}
					}
				})
		})
	})

	wpm.scriptTagObserver.observe(document.head, {childList: true, subtree: true})
	// jQuery(document).on("DOMContentLoaded", () => wpm.scriptTagObserver.disconnect())
	document.addEventListener("DOMContentLoaded", () => wpm.scriptTagObserver.disconnect())

	wpm.shouldScriptBeActive = node => {

		if (
			wpmDataLayer.shop.cookie_consent_mgmt.explicit_consent ||
			wpmConsentValues.visitorHasChosen
		) {

			if (wpmConsentValues.mode === "category" && $(node).data("wpm-cookie-category").split(",").some(element => wpmConsentValues.categories[element])) {
				return true
			} else if (wpmConsentValues.mode === "pixel" && wpmConsentValues.pixels.includes($(node).data("wpm-pixel-name"))) {
				return true
			} else if (wpmConsentValues.mode === "pixel" && $(node).data("wpm-pixel-name") === "google" && ["google-analytics", "google-ads"].some(element => wpmConsentValues.pixels.includes(element))) {
				return true
			} else if (wpmDataLayer?.pixels?.google?.consent_mode?.active && $(node).data("wpm-pixel-name") === "google") {
				return true
			} else {
				return false
			}
		} else {
			return true
		}
	}


	wpm.unblockScript = (scriptNode, removeAttach = false) => {

		if (removeAttach) $(scriptNode).remove()

		let wpmSrc = $(scriptNode).data("wpm-src")
		if (wpmSrc) $(scriptNode).attr("src", wpmSrc)

		scriptNode.type = "text/javascript"

		if (removeAttach) $(scriptNode).appendTo("head")

		// jQuery(document).trigger("wpmPreLoadPixels")
		document.dispatchEvent(new Event("wpmPreLoadPixels"))
	}

	wpm.blockScript = (scriptNode, removeAttach = false) => {

		if (removeAttach) $(scriptNode).remove()

		if ($(scriptNode).attr("src")) $(scriptNode).removeAttr("src")
		scriptNode.type = "blocked/javascript"

		if (removeAttach) $(scriptNode).appendTo("head")
	}

	wpm.unblockAllScripts = (analytics = true, ads = true) => {
		// jQuery(document).trigger("wpmPreLoadPixels")
		wpm.setConsentValueCategories(analytics, ads)
		document.dispatchEvent(new Event("wpmPreLoadPixels"))
	}

	wpm.unblockSelectedPixels = () => {
		// jQuery(document).trigger("wpmPreLoadPixels")
		document.dispatchEvent(new Event("wpmPreLoadPixels"))
	}

	wpm.explicitConsentStateAlreadySet = () => {

		if (wpmConsentValues.explicitConsentStateAlreadySet) {
			return true
		} else {
			wpmConsentValues.explicitConsentStateAlreadySet = true
		}
	}


	/**
	 * Block or unblock scripts for each CMP immediately after cookie consent has been updated
	 * by the visitor.
	 */

	/**
	 * Borlabs Cookie
	 * If visitor accepts cookies in Borlabs Cookie unblock the scripts
	 */
	document.addEventListener("borlabs-cookie-consent-saved", () => {
		wpm.updateConsentCookieValues()

		if (wpmConsentValues.mode === "pixel") {

			wpm.unblockSelectedPixels()
			wpm.updateGoogleConsentMode(wpmConsentValues.pixels.includes("google-analytics"), wpmConsentValues.pixels.includes("google-ads"))
		} else {

			wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
			wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		}
	})

	/**
	 * Cookiebot
	 * 	If visitor accepts cookies in Cookiebot unblock the scripts
	 * 	https://www.cookiebot.com/en/developer/
	 */
	window.addEventListener("CookiebotOnAccept", () => {
		if (Cookiebot.consent.statistics) wpmConsentValues.categories.analytics = true
		if (Cookiebot.consent.marketing) wpmConsentValues.categories.ads = true

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)

	}, false)

	/**
	 * Cookie Script
	 * If visitor accepts cookies in Cookie Script unblock the scripts
	 * https://support.cookie-script.com/article/20-custom-events
	 */
	// jQuery(document).on("CookieScriptAccept", e => {
	document.addEventListener("CookieScriptAccept", e => {

		if (e.detail.categories.includes("performance")) wpmConsentValues.categories.analytics = true
		if (e.detail.categories.includes("targeting")) wpmConsentValues.categories.ads = true

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})

	/**
	 * Cookie Script
	 * If visitor accepts cookies in Cookie Script unblock the scripts
	 * https://support.cookie-script.com/
	 */
	// jQuery(document).on("CookieScriptAcceptAll", () => {
	document.addEventListener("CookieScriptAcceptAll", () => {

		wpm.setConsentValueCategories(true, true)
		wpm.unblockAllScripts(true, true)
		wpm.updateGoogleConsentMode(true, true)
	})

	/**
	 * Complianz Cookie
	 *
	 * If visitor accepts cookies in Complianz unblock the scripts
	 */

	wpm.cmplzStatusChange = (cmplzConsentData) => {

		if (cmplzConsentData.detail.categories.includes("statistics")) wpm.updateConsentCookieValues(true, null)
		if (cmplzConsentData.detail.categories.includes("marketing")) wpm.updateConsentCookieValues(null, true)

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	}

	// jQuery(document).on("cmplzStatusChange", wpm.cmplzStatusChange)
	document.addEventListener("cmplzStatusChange", wpm.cmplzStatusChange)
	// jQuery(document).on("cmplz_status_change", wpm.cmplzStatusChange)
	document.addEventListener("cmplz_status_change", wpm.cmplzStatusChange)

	// Cookie Compliance by hu-manity.co (free and pro)
	// If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (free version)
	// https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
	// jQuery(document).on("setCookieNotice", () => {
	document.addEventListener("setCookieNotice", () => {
		wpm.updateConsentCookieValues()

		wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
		wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
	})

	/**
	 * Cookie Compliance by hu-manity.co (free and pro)
	 * If visitor accepts cookies in Cookie Notice by hu-manity.co unblock the scripts (pro version)
	 * https://wordpress.org/support/topic/events-on-consent-change/#post-15202792
	 * Because Cookie Notice has no documented API or event that is being triggered on consent save or update
	 * we have to solve this by using a mutation observer.
	 *
	 * @type {MutationObserver}
	 */

	wpm.huObserver = new MutationObserver(mutations => {
		mutations.forEach(({addedNodes}) => {
			[...addedNodes]
				.forEach(node => {

					if (node.id === "hu") {

						// jQuery(".hu-cookies-save").on("click", function () {
						// jQuery(".hu-cookies-save") in pure JavaScript
						document.querySelector(".hu-cookies-save").addEventListener("click", () => {
							wpm.updateConsentCookieValues()
							wpm.unblockAllScripts(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
							wpm.updateGoogleConsentMode(wpmConsentValues.categories.analytics, wpmConsentValues.categories.ads)
						})
					}
				})
		})
	})

	if (window.hu) {
		wpm.huObserver.observe(document.documentElement || document.body, {childList: true, subtree: true})
	}

	/**
	 * Usercentrics Event Listeners
	 *
	 * https://docs.usercentrics.com/#/v2-events?id=usage-as-window-event
	 */

	window.addEventListener("ucEvent", e => {
		if (e.detail && e.detail.event == "consent_status") {
			// check for consent status of service "Google Ads Remarketing"
			if (e.detail["Google Ads Remarketing"] === true) {
				console.log("Google Ads Remarketing has consent")
			} else {
				console.log("Google Ads Remarketing has no consent")
			}
		}
	})

	// https://docs.usercentrics.com/#/v2-events?id=uc_ui_cmp_event
	window.addEventListener("UC_UI_CMP_EVENT", event => {

		if (event.detail.type === "ACCEPT_ALL") {
			// console.log('accept all');

			pmw.consentAcceptAll()
		}

		if (event.detail.type === "DENY_ALL") {
			pmw.consentRevokeAll()
		}

		if (event.detail.type === "SAVE") {
			console.log("event.detail", event.detail)
		}
	})


	/**
	 * OneTrust Event Listeners
	 *
	 * CookiePro by OneTrust doesn't emit any events when the user accepts or declines cookies.
	 */

	// There are two accept all buttons. One in the first banner and one in the settings window. Both have different identifiers.
	jQuery("#accept-recommended-btn-handler, #onetrust-accept-btn-handler").on("click", () => {

		// If OneTrust is not loaded, return
		if (typeof window.OneTrust === "undefined") return

		pmw.consentAcceptAll()
	})

	// There are two revoke all buttons. One in the first banner and one in the settings window. Both have different identifiers.
	jQuery(".ot-pc-refuse-all-handler, #onetrust-reject-all-handler").on("click", () => {
		pmw.consentRevokeAll()
	})

	// There is one save button that saves mixed consent. It is in the settings window. We reload the page after saving to reflect the changes.
	jQuery(".save-preference-btn-handler.onetrust-close-btn-handler").on("click", () => {
		location.reload()

		// OneTrust.OnConsentChanged(function (e) {
		// 	pmw.consentAdjustSelectively({
		// 		analytics: e.detail.includes("2"),
		// 		ads      : e.detail.includes("4"),
		// 	})
		// })
	})

	/**
	 * Termly Event Listeners
	 *
	 * The event listener is within /sources/termly_web/resource-blocker/src/constants/lifeCycle.js
	 */

	document.addEventListener("termlyConsent", function (e) {

		const consent = e.detail

		if (consent.advertising && consent.analytics) {
			pmw.consentAcceptAll()
		} else if (!consent.advertising && !consent.analytics) {
			pmw.consentRevokeAll()
		} else {
			pmw.consentAdjustSelectively({
				analytics: consent.analytics,
				ads      : consent.advertising,
			})
		}
	})

}(window.wpm = window.wpm || {}, jQuery));


(function (pmw, $, undefined) {

	/**
	 * Pixel Manager Cookie Consent API
	 */

	// Accept consent for all cookies
	pmw.consentAcceptAll = (settings = {}) => {

		settings.duration = settings.duration || 365

		pmw.consentSetCookie(true, true, settings.duration)
		wpm.unblockAllScripts(true, true)
		wpm.updateGoogleConsentMode(true, true)
	}

	// Accept consent selectively
	pmw.consentAdjustSelectively = (settings) => {

		// If settings.analytics is set, keep it, otherwise set it to wpm.getConsentValues().categories.analytics
		settings.analytics = settings.analytics !== undefined ? settings.analytics : wpm.getConsentValues().categories.analytics
		settings.ads       = settings.ads !== undefined ? settings.ads : wpm.getConsentValues().categories.ads
		settings.duration  = settings.duration || 365

		pmw.consentSetCookie(settings.analytics, settings.ads, settings.duration)
		wpm.unblockAllScripts(settings.analytics, settings.ads)
		wpm.updateGoogleConsentMode(settings.analytics, settings.ads)
	}

	// Remove consent for all cookies
	pmw.consentRevokeAll = (settings = {}) => {

		settings.duration = settings.duration || 365

		wpm.setConsentValueCategories(false, false)
		pmw.consentSetCookie(false, false, settings.duration)
		wpm.updateGoogleConsentMode(false, false)
	}

	// Set a cookie called pmw_cookie_consent with the value of pmw_cookie_consent
	// and set the default expiration date to 1 year from now
	pmw.consentSetCookie = (analytics, ads, duration = 365) => {
		wpm.setCookie("pmw_cookie_consent", JSON.stringify({analytics, ads}), duration)
	}

	// Trigger an event once the PMW consent management has been loaded
	jQuery(document).trigger("pmw_cookie_consent_management_loaded")

}(window.pmw = window.pmw || {}, jQuery))
